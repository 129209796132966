/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql,Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

import LayoutMain from 'components/LayoutMain';
import { Header, Footer, Button, Modal } from 'components';
import { ContactForm } from 'components/forms';
import Map, { Marker } from 'components/Map';
import bgImg from 'assets/images/landingSection1BG3.png';
import useLayout from 'hooks/useLayout';
import { BigPictureSection, CardsSection, ContactSection, Pricing, Faqs } from 'components/sections';
import { PopupModal } from "react-calendly";

const MAP_COORDINATES = {
  lat: 41.8906171,
  lng: -87.62691
};

const HomePage = () => {
  const images = useStaticQuery(graphql`
    {
      peopleCard1: file(relativePath: { eq: "new_agents.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peopleCard2: file(relativePath: { eq: "new_team1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peopleCard3: file(relativePath: { eq: "new_man_broker.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section5Graphics: file(relativePath: { eq: "new_team_approach.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 443) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Section10Graphics: file(relativePath: { eq: "landingSection10Graphics.png" }) {
        childImageSharp {
          fluid(maxWidth: 338, maxHeight: 416) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      moreThanSoft: file(relativePath: { eq: "new_more_than_assistant.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [openCalendly, setOpenCalendly] = useState<boolean>(false);

  const [openContactForm, setOpenContactForm] = useState<boolean>(false);
  const [openRequestForm, setOpenRequestForm] = useState<boolean>(false);
  const [pageTop, setPageTop] = useState(true);
  const [canRender, setCanRender] = useState(false);
  const layout = useLayout();

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) setPageTop(true);
      else if (pageTop !== false) {
        setPageTop(false);
      }
    };
    return () => {
      window.onscroll = null;
    };
  }, [pageTop]);

  useEffect(() => {
    setPageTop(window.pageYOffset === 0);
    setCanRender(true);
  }, []);

  return (
    <LayoutMain>
      <Header
        border={!pageTop}
        onClickRequest={() => setOpenRequestForm(true)}
        onClickContact={() => setOpenContactForm(true)}
      />
        {
          canRender ? <PopupModal
          url="https://calendly.com/mybrokertools"
          onModalClose={() => setOpenCalendly(false)}
          open={openCalendly}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("rootOfModal")}
        /> : null
        }
        

      <StyledHomePage>
        <div id="rootOfModal"></div>
        <section className="top-section">
          <p>Don't manage employees, Grow sales</p>
          <h1>Focus on selling real estate<br></br>and let us handle the rest</h1>
          <p className="full-text-type-1">
        
           We provide Real Estate Agents with admin and support team, no matter how big or small you are, all for a low flat fee. It has never been easier to grow.
            
            
          </p>
          <div className="top-section-container">
          <Link to="/onboard" className="side-menu__logo">
            <StyledButton>Get started</StyledButton>
          </Link>
            
         
          </div>
          
        </section>

        <CardsSection
            className="clients-section"
            sectionTitle=""
            cards={[
              {
                key: 1,
                image: images.peopleCard2.childImageSharp.fluid,
                title: 'Agents',
                text:
                  "Focus on selling, not on admin work. 99.9% of all requests are handled within 15 minutes by our tech-powered back-office staffers."
              },
              {
                key: 2,
                image: images.peopleCard3.childImageSharp.fluid,
                title: 'Teams',
                text:
                  "Enhance your team's output and increase your growth by integrating our tech powered back-office solution in your business."
              },
              {
                key: 3,
                image: images.peopleCard1.childImageSharp.fluid,
                title: 'Managing Brokers',
                text:
                  'Simplify your operations and scale up with full transparency. MybrokerTools offers you reliable back-office staff at a fraction of the cost of an employee.'
              },
              
              
            ]}
          />

        <BigPictureSection
          className="software-section"
          imagePosition="left"
          image={{ fluid: images.moreThanSoft.childImageSharp.fluid }}>
          <h2>More than just an assistant.</h2>
          <p>
          We provide an on demand workforce, automate majority of the back-office work 
            and free up the time spent on administrative duties,
            helping you to focus your time where it counts: driving deals, boosting organic
            traffic, and promoting business expansion. Save time, money and energy, while delivering unprecedented service to your clients.
          </p>
          <StyledButton onClick={() => setOpenCalendly(true)}>Let's chat</StyledButton>
        </BigPictureSection>

        {/*<Pricing
            className="clients-section"
            sectionTitle="Simple Pricing"
          />*/}


           

        <BigPictureSection
          className="software-section"
          imagePosition="right"
          image={{ fluid: images.section5Graphics.childImageSharp.fluid }}>
          <h2>Team based approach.</h2>
          <p>
            We supply you with a team to cover all of your needs, no matter how many transactions you close per year. We are here for you 7 days a week, 365 days a year.
          </p>
          <StyledButton onClick={() => setOpenCalendly(true)}>Let's chat</StyledButton>
        </BigPictureSection>

        <Faqs
          className="faqs-section"
          sectionTitle="You might have some questions."
          
          cards={[
            {
              key: 1,
              question: 'Why should I use the MyBrokerTools Team?',
              text:
                'Our Team handles all of your pre-contract and post-contract admin work, tasks, paperwork and coordination. Any issue you run into we have you covered via our team or our trusted vendor network. By freeing up your workload you can focus on what you do best: selling.'
            },
            {
              key: 2,
              question: 'How do you streamline the transaction?',
              text:
                'We built our own network of reliable vendors that go above and beyond. We even supplied them with their own automation tools so your transaction flows smoother than ever. Stop worrying about missing documents or incomplete data. Our integrated software network makes this a thing of the past.'
            },
            {
              key: 3,
              question: 'How can you do all of this, so cheap?',
              text:
                'We believe in human resources powered by tech. An assistant can only do so much with excel, existing softwares, paper. Our A-Z solution enables us to cut costs, not quality.'
            },
          ]}
        />

       

        <LazyLoad height={300}>
          <Map center={MAP_COORDINATES}>
            <Marker lat={MAP_COORDINATES.lat} lng={MAP_COORDINATES.lng} />
          </Map>
        </LazyLoad>

        <ContactSection />

        <BigPictureSection
          className="schedule-section"
          imagePosition="left"
          image={{ fluid: images.Section10Graphics.childImageSharp.fluid }}>
          <h2>Let’s get started.</h2>
          <p>Your transformation is just a tap away.</p>
          <StyledButton onClick={() => setOpenCalendly(true)}>Request a demo </StyledButton>
        </BigPictureSection>
      </StyledHomePage>

      <Footer />
      <Modal
        modalTitle={<>Our friendly specialists would love to chat!</>}
        open={openContactForm}
        onClose={() => setOpenContactForm(false)}>
        <ContactForm extended modal onSubmit={() => setOpenContactForm(false)} />
      </Modal>
      <Modal
        modalTitle="Our specialists will be in touch!"
        open={openRequestForm}
        onClose={() => setOpenRequestForm(false)}>
        <ContactForm
          modal
          subject="Request a Demo"
          buttonText="Send Request"
          onSubmit={() => setOpenRequestForm(false)}
        />
      </Modal>
    </LayoutMain>
  );
};

const StyledHomePage = styled(LayoutMain)`
  .full-text-type-1 {
    max-width: 620px;
  }
  .full-text-type-2 {
    display: inline-block;
    max-width: 570px;
  }
  .full-text-type-3 {
    max-width: 850px;
    margin: 0 auto;
  }
  section {
    padding: 32px 20px;
    text-align: center;
    width: 100%;
    max-width: 1246px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;

    &.top-section {
      font-size: 20px;
      line-height: 28px;
      position: relative;
      overflow: hidden;
      margin-top:0;

      p:first-of-type {
        margin-top: 104px;
        font-weight: bold;
      }
      p {
        margin: 32px auto;
      }

      .bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 618px;
        margin: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        z-index: -1;

        .top-section-graphics-1 {
          width: 100%;
          max-width: 890px;
          height: 618px;
          top: 0;
        }
      }

      .top-section-graphics-2 {
        margin-top: 118px;
      }
      .top-section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &.solutions-card-section {
      .cards-grid {
        display: grid;
        width: 100%;
        margin: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px;
        justify-items: center;
        margin-top: 60px;
      }
    }
    &.schedule-section {
      .gatsby-image-wrapper {
        max-width: 350px;
        height: auto;
      }
    }
    &.dashboard-photo-section {
      padding: 0 16px;
    }

    /* &:nth-of-type(7) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 80px;
      text-align: left;

      .picture-block {
        text-align: center;
        p {
          margin: 40px 0;
        }
      }
    } */

    /* &:nth-of-type(6) {
      & > h2,
      & > p {
        text-align: left;
      }
    } */

    /* &:nth-of-type(8) {
      justify-items: center;

      .gatsby-image-wrapper {
        width: 100%;
        max-width: 338px;
        height: 416px;
      }
    } */
  }

  .clients-section img{border-radius: 15%;}

  h1 {
    margin: 32px 0;
    font-size: 64px;
    line-height: 64px;
    font-weight: normal;
  }
  h2 {
    margin: 24px 0;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
  }
  h6 {
    margin: 0 0 16px 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
  }

  .dashboard-photo {
    max-width: 920px;
    margin: 0 auto 50px;
  }

  .map {
    max-width: 1246px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 0;
    &__item {
      margin: 20px 0;
      padding: 0 20px;
      flex: 0 0 33.33333%;
      text-align: left;
    }
    &__icon {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .container-fluid {
      padding-left: 60px;
      padding-right: 60px;
    }
    .container {
      max-width: 60rem;
    }
    .top-section-graphics-3 {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .list {
      &__item {
        flex: 0 50%;
      }
    }
    .contact-us-wrapper {
      grid-gap: 0 64px;
    }
    h2 {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 24px;
    }

    h6 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 16px;
    }
    .list {
      &__item {
        margin: 12px 0;
        padding: 0;
        flex: 100%;
      }
    }
    section {
      padding: 20px 16px;
      &.top-section {
        .top-section-graphics-2 {
          display: none;
        }
        .top-section-graphics-3 {
          max-width: 332px;
          margin: 0 auto;
          margin-top: 68px;
        }

        h1 {
          font-size: 36px;
          line-height: 42px;
          margin: 0;
        }
        p {
          margin: 24px auto 32px;
        }
      }
      &.schedule-section {
        .gatsby-image-wrapper {
          max-width: 248px;
          height: auto;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    section {
      &.solutions-card-section {
        .cards-grid {
          margin-top: 0;
          grid-template-columns: 1fr;
          grid-gap: 24px;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export default HomePage;
